#page-header {
  height: 100px;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  transition: background .3s, height .3s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#page-header.page-scrolled {
  height: 80px;
  background: #0e0e0e80;
}

#page-header #hamburger-menu {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-left: 14px;
  padding: 16px;
  display: flex;
}

#page-header #hamburger-menu.open-menu .line:nth-child(2) {
  opacity: 0;
  transform: translateX(30px);
}

#page-header #hamburger-menu.open-menu .line:nth-child(1) {
  transform: translateY(-2.8px)rotate(45deg)scaleY(.93)scaleX(1.84);
}

#page-header #hamburger-menu.open-menu .line:nth-child(3) {
  transform: translateY(2.8px)rotate(-45deg)scaleY(.93)scaleX(1.84);
}

#page-header #hamburger-menu .line {
  height: 3px;
  width: 19px;
  background-color: #fff;
  transition: all .3s;
}

#page-header #hamburger-menu .line:nth-child(1) {
  transform-origin: 0 0;
}

#page-header #hamburger-menu .line:nth-child(3) {
  transform-origin: 0 100%;
}

#page-header #hamburger-menu .line:nth-child(2) {
  width: 27px;
}

#page-header #logo-icon {
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#page-header #mobile-social-links {
  display: none;
}

@media (max-width: 1140px) {
  #page-header {
    height: 60px !important;
  }

  #page-header #hamburger-menu.open-menu .line:nth-child(1) {
    transform: translateY(1px)rotate(45deg)scaleY(.66)scaleX(1.31);
  }

  #page-header #hamburger-menu.open-menu .line:nth-child(3) {
    transform: translateY(-1px)rotate(-45deg)scaleY(.66)scaleX(1.31);
  }

  #page-header .social-links {
    display: none !important;
  }

  #logo-icon img {
    height: 25px;
  }

  #mobile-social-links {
    margin-right: 20px;
    display: block !important;
  }
}

/*# sourceMappingURL=index.dd45b301.css.map */
