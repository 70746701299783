@import "constants";

#page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  transition: $animation-time background, $animation-time height;

  &.page-scrolled {
    background: $faded-background;
    height: 80px;
  }

  #hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    padding: 16px;
    margin-left: 14px;

    &.open-menu .line {
      &:nth-child(2) {
        transform: translateX(30px);
        opacity: 0;
      }

      // these lines are currently overwritten in js to fix a sass bug
      &:nth-child(1) {
        transform: translateY(-2.8px) rotate(45deg) scaleY(0.93) scaleX(1.84);
      }
      &:nth-child(3) {
        transform: translateY(2.8px) rotate(-45deg) scaleY(0.93) scaleX(1.84);
      }
    }

    .line {
      height: 3px;
      width: 19px;
      background-color: white;
      transition: $animation-time all;

      &:nth-child(1) {
        transform-origin: left top;
      }

      &:nth-child(3) {
        transform-origin: left bottom;
      }

      &:nth-child(2) {
        width: 27px;
      }
    }
  }

  #logo-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
  }

  #mobile-social-links {
    display: none;
  }
}

@media (max-width: $mobile-screen-width) {
  #page-header {
    height: 60px !important;

    #hamburger-menu.open-menu .line {
      // these lines are currently overwritten in js to fix a sass bug
      &:nth-child(1) {
        transform: translateY(1px) rotate(45deg) scaleY(0.66) scaleX(1.31);
      }
      &:nth-child(3) {
        transform: translateY(-1px) rotate(-45deg) scaleY(0.66) scaleX(1.31);
      }
    }

    .social-links {
      display: none !important;
    }
  }

  #logo-icon img {
    height: 25px;
  }

  #mobile-social-links {
    display: block !important;
    margin-right: 20px;
  }
}
